<template>
  <vz-autocomplete
    ref="autocomplete"
    value-key="guid"
    value-template="{name}"
    title-template="{type} {name}"
    description-template="{region_s}"
    :modelValue="modelValue"
    :label="label"
    :items="items"
    :use-spinner="useSpinner"
    :model-modifiers="{ trimStart: true }"
    v-bind="$attrs"
    @search="search"
  >
    <div v-if="regionDescription && inputValue" class="city-autocomplete-region">
      {{ regionDescription }}
    </div>
  </vz-autocomplete>
</template>

<script setup lang="ts">
import VzAutocomplete from '~/components/ui-kit/vz-autocomplete.vue'
import {getAddressByGuid, getLocations} from '@/api/city'
import { useCityStore } from '~/stores/city'
import { storeToRefs } from 'pinia'
import { Location } from '~/types/types'
import {until} from '@vueuse/shared'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: undefined
  },
  searchOptions: {
    type: Object,
    default: () => ({})
  },
  showRegion: {
    type: Boolean,
    default: false
  },
  onlyRclSearch: {
    type: Boolean,
    default: false
  }
})

const { rclCityList } = storeToRefs(useCityStore())

// Условно наследуем компонент от VzAutocomplete
defineOptions({
  inheritAttrs: false
})

const items = ref<Location[]>([])
const selected = ref({} as Location)
const useSpinner = ref(false)
const autocomplete = ref()

const label = computed(() => {
  return props.label === undefined ? 'Населённый пункт' : props.label
})

const inputValue = computed(() => {
  return autocomplete.value?.inputValue
})

const regionDescription = computed(() => {
  if (!props.showRegion || !selected.value?.region_s) {
    return ''
  }
  return selected.value.region_s
})

async function search(value?: string): Promise<void> {
  useSpinner.value = true
  if (!value && !Object.values(props.searchOptions).filter(v => !!v).length && !props.searchOptions.rcl) {
    await until(() => !!rclCityList.value.length).toBe(true)
    items.value = rclCityList.value
  } else if (value && props.onlyRclSearch) {
    items.value = rclCityList.value.filter((v) => v?.name?.toLowerCase().includes(value.toLowerCase()))
  } else {
    items.value = await getLocations(value, { ...props.searchOptions }).finally(() => {
      useSpinner.value = false
    })
  }

  if (props.searchOptions?.fake === 0) {
    items.value = items.value.filter((i) => !i.fake)
  }

  useSpinner.value = false
}

watch(
  () => props.searchOptions,
  () => {
    search(props.searchOptions?.search)
  },
  { immediate: true }
)

// Выставляем текущее значение и добавляем его в начало списка
watch(() => [items.value, props.modelValue],
  async () => {
    if (selected.value?.guid === props.modelValue) {
      return
    }
    selected.value = items.value.find(city => city.guid === props.modelValue)
    if (props.modelValue && !selected.value && items.value.length) {
      let selectedLocation = (await getAddressByGuid(props.modelValue))?.location
      if (selectedLocation) {
        selected.value = selectedLocation
        items.value = [selectedLocation, ...items.value]
      }
    }
  }, { immediate: true }
)
defineExpose({
  selected,
  search
})
</script>

<style scoped lang="scss">
.city-autocomplete-region {
  color: $font-lower;
  padding: 0 15px 0;
  font-size: $fs-12;
}
</style>
